<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
            <CCol md="7">
              <h4>Transactions Fee</h4>
            </CCol>
            <CCol md="3">
              <CInput label="Search" v-model="filter" placeholder="Type to Search" horizontal />
            </CCol>
            <CCol md="2">
              <!-- <b-button-group>
                <b-dropdown right text="Actions" variant="primary">
                  <router-link :to='"/settings/fee/add"' tag="b-dropdown-item"> New Fee </router-link>
                  <router-link to="#" exact v-on:click.native="getAllList()" tag="b-dropdown-item"> Show All Fee
                  </router-link>
                </b-dropdown>
              </b-button-group> -->
              <b-button variant="primary" to="/settings/fee/add">New Fee</b-button>

            </CCol>
          </CRow>
          <CRow>
            <CCol col="12" class="text-left">
              <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
            </CCol>
          </CRow>
        </CCardHeader><br />
        <CCardBody>

          <CRow>
            <CCol md="4">
              <div class="form-group">
                <label for="input-small">Status</label>
                <b-form-select @change="onChangeData" v-model="status" :options="optStatus"></b-form-select>
              </div>
            </CCol>
            <CCol md="4">
              <div class="form-group">
                <label for="input-small">Transaction Type</label>
                <b-form-select @change="onChangeFilter" v-model="trType" :options="optTrType"></b-form-select>
              </div>
            </CCol>
            <CCol md="4">
              <div class="form-group">
                <label for="input-small">User Tier</label>
                <b-form-select @change="onChangeFilter" v-model="userTier" :options="optUsrTier"></b-form-select>
              </div>
            </CCol>
          </CRow>

          <div class="table-responsive table">
            <b-table striped hover :items="items" :fields="fields" sort-by="transactionType" sort-asc="true"
              :current-page="currentPage" :per-page="perPage" :filter="filter">

              <template v-slot:cell(createDate)="{ item }">
                {{ item.createDate | dateSubstr }}
              </template>

              <template v-slot:cell(actions)="{ item }">
                <b-dropdown variant="info" text="Actions" size="md">
                  <router-link :to='"/settings/fee/" + item.id' tag="b-dropdown-item"> Edit </router-link>
                </b-dropdown>
              </template>
            </b-table>
          </div>

          <b-row>
            <b-col sm="3">
              <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>
            <b-col sm="9">
              <b-form-select
                :options="[{ text: 10, value: 10 }, { text: 20, value: 20 }, { text: 50, value: 50 }, { text: 100, value: 100 }]"
                v-model="perPage" style="width:100px;">
              </b-form-select>
            </b-col>
          </b-row>

        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template> 

<script>
import AuthService from '@/api/AuthService.js';
import TrService from '@/api/TransactService.js';

export default {
  name: "users",
  data() {
    return {
      spinner: false,
      items: [],
      data: [],
      fields: [
        {
          key: 'transactionType',
          label: 'Transaction Type',
          sortable: true
        },
        {
          key: 'userTier',
          label: 'User Tier',
          sortable: true
        },
        {
          name: 'feeType',
          key: 'feeType',
          label: 'Fee Type',
          sortable: true
        },
        {
          key: 'amount',
          label: 'Amount',
          sortable: true
        },
        {
          key: 'minAmount',
          label: 'Min Amount',
          sortable: true
        },
        {
          key: 'recordStatus',
          label: 'Status',
          sortable: true
        },
        {
          key: 'createDate',
          label: 'Created Date',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Actions'
        }
      ],
      status: 'ACTIVE',
      optStatus: ['ACTIVE', 'INACTIVE', 'ALL'],
      optTrType: [],
      optUsrTier: [],
      trType: 'ALL',
      userTier: 'ALL',
      currentPage: 1,
      perPage: 10,
      filter: null,
      cbactive: false,
      seen: false,
      msg: '',
      color: ''
    };
  },
  directives: {
    dateSubstr: {
      update(el) {
        var temp = el.value.toString().split(".");
        el.value = temp[0].replace("T", " ");
      },
    },
  },

  filters: {
    upper(value) {
      if (!value) return ''
      value = value.toString()
      return value.toUpperCase()
    },
    lower(value) {
      if (!value) return ''
      value = value.toString()
      return value.toLowerCase()
    },

    dateSubstr(value) {
      if (!value) return ''
      value = value.toString()
      return value.substring(0, 10)
    },

    dateWithTime(value) {
      if (!value) return ''
      var temp = value.toString().split(".");
      value = temp[0].replace("T", " ");
      return value;
    },

    IdSubstr(value) {
      if (!value) return ''
      value = value.toString()
      return value.substring(0, 5) + '...'
    }


  },
  created() {
    this.checkAccessByRole();
    this.getConfigList();
    var feeId = this.$route.query.feeId;
    if (feeId) { this.findTrFee(feeId); } else { this.getTrFeeList(this.status); }
  },
  methods: {

    isSuperAdmin() {
      if (localStorage.getItem('sa') === "false") {
        this.$router.push('/dashboard');
      }
    },

    checkAccessByRole() {
        var strRoles  = localStorage.getItem('roles');
        var roles     = strRoles.split(",");
        var isAllowed = roles.includes("ROLE_MANAGE_TRANSACTION_FEE") ? true : false;
        if(!isAllowed) { this.$router.push('/dashboard'); }
    },

    stringToBoolean: function (string) {
      switch (String(string).toLowerCase().trim()) {
        case "true": case "yes": case "1": return true;
        case "false": case "no": case "0": case null: return false;
        default: return Boolean(string);
      }
    },

    findTrFee: function (feeId) {
      TrService.findTrFee(feeId).then(resp => {  //console.log(resp); 
        this.items.push(resp);
      }, error => {
        console.log('error');
      });
    },

    getAllList() {
      this.$router.push({ path: '/settings/fee' });
      location.reload();
    },

    onChangeData() {
      var status = this.status;
      this.getTrFeeList(status);
      this.trType = 'ALL'; this.userTier = 'ALL';
    },

    onChangeFilter() {
      var trType = this.trType;
      var userTier = this.userTier;
      var data = this.data;
      let list = [];
      for (var key in data) {
        var row = data[key];
        if (row.transactionType == trType && row.userTier == userTier) {
          list.push(row);
        } else if (row.transactionType == trType && userTier == 'ALL') {
          list.push(row);
        } else if (trType == 'ALL' && row.userTier == userTier) {
          list.push(row);
        } else if (trType == 'ALL' && userTier == 'ALL') {
          list.push(row);
        }
      }
      this.items = list;
    },

    sortByProperty(property) {
      return function (a, b) {
        if (a[property] > b[property])
          return 1;
        else if (a[property] < b[property])
          return -1;
        return 0;
      }
    },

    getTrFeeList: function (status) {
      TrService.getTrFeeList(status).then(resp => {
        resp.sort((a,b)=> (a.transactionType - b.transactionType || a.userTier.localeCompare(b.userTier)));
        this.items = resp;
        this.data  = resp;
      }, error => {
        this.spinner = false;
      });
    },

    getConfigList: function () {
      AuthService.getConfigList().then(resp => {  //console.log(resp);       

        var trtype = resp.txnFeeTxnType; var trTypeSrc = [];
        trTypeSrc.push({ value: 'ALL', text: 'ALL' });
        for (var key in trtype) {
          var trTypeName = trtype[key];
          trTypeSrc.push({ value: trTypeName, text: trTypeName });
        }

        trTypeSrc.sort(this.sortByProperty("value"));
        this.optTrType = trTypeSrc;

        var usrTLType = resp.userTierList; var userTLSrc = [];
        userTLSrc.push({ value: 'ALL', text: 'ALL' });
        for (var key in usrTLType) {
          var usrTLName = usrTLType[key];
          userTLSrc.push({ value: usrTLName, text: usrTLName });
        }

        userTLSrc.sort(this.sortByProperty("value"));
        this.optUsrTier = userTLSrc;

      }, error => {
        console.log('error');
      });
    },

  }
};

</script>
<style>
.table {
  overflow-y: scroll;
}
</style>